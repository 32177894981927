<template>
  <b-modal
    id="set-off-payrate-modal"
    ref="set-off-payrate-modal"
    hide-footer
    size="md"
    title="เปิด/ปิด อัตราจ่ายรายหวย"
    @show="setData"
    @hidden="resetModal"
    class="p-0"
  >
    <form
      id="lotto-setting-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <table class="table table-sm table-striped table-hover mb-0">
        <thead>
          <tr>
            <th colspan="2" class="text-center text-primary">{{ marketTitle }}</th>
          </tr>
          <tr>
            <th width="50%" class="p-1 text-center">อัตราจ่าย</th>
            <th class="p-1 text-center">เปิด/ปิด</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="rate in rates" :key="rate._id">
            <td class="text-center">{{ rate.rateTitle }}</td>
            <td class="text-center">
              <b-form-checkbox v-model="input[rate._id]" name="check-button" switch :class="[input[rate._id] ? 'text-success' : 'text-danger']" />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-end p-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import MarketService from '@/services/marketService'
import Swal from 'sweetalert2'

export default {
  name: 'SetOffPayRateModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    rates: {
      type: Array,
      default: []
    },
    data: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      input: {}
    }
  },
  computed: {
    marketTitle() {
      return this.data?.marketTitle ?? '-'
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['set-off-payrate-modal'].show()
    },
    hideModal() {
      this.$refs['set-off-payrate-modal'].hide()
    },
    setData() {
      const offPayRates = this.data?.offPayRates ?? {}
      const converseSetting = this.rates.reduce((settings, rate)=>{
        const set = offPayRates?.[rate._id] ?? false
        settings[rate._id] = !set
        return settings
      }, {})

      this.input = {
        ...offPayRates,
        ...converseSetting
      }
    },
    handleSubmit() {
      const offPayRates = this.data?.offPayRates ?? {}

      const converseInput = this.rates.reduce((settings, rate)=>{
        settings[rate._id] = !this.input[rate._id]
        return settings
      }, {})

      const updateData = {
        ...offPayRates,
        ...converseInput
      }

      MarketService.updateMarket(this.data._id, {
        offPayRates: updateData
      })
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#set-off-payrate-modal {
  .modal-body {
    padding: 0;
  }
}
</style>
