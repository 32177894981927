<template>
  <div class="container-fluid pb-4">
    <div class="page-header d-flex justify-content-between align-items-center">
      <strong>เปิด/ปิด อัตราจ่ายรายหวย</strong>
    </div>

    <div class="bg-light">
      <table class="table table-bordered table-striped table-hover">
        <thead>
          <tr class="text-center">
            <th class="p-1">หวย</th>
            <th width="80" class="p-1" v-for="rate in rates" :key="rate._id">{{ rate.rateTitle }}</th>
            <th width="60"></th>
          </tr>
        </thead>
        <tbody v-for="group in groups" :key="group._id">
          <tr class="text-primary">
            <th :colspan="rates.length + 2" class="p-1">{{ group.groupTitle }}</th>
          </tr>
          <tr v-for="(market, index) in group.markets">
            <td class="p-1">{{market.marketTitle}}</td>

            <td class="text-center p-1" v-for="rate in rates" :key="`${market._id}-${rate._id}`">
              <i v-if="market.offPayRates[rate._id]" class="fas fa-times fa-lg text-danger"></i>
              <i v-else class="fas fa-check fa-lg text-success"></i>
            </td>

            <td class="text-center p-1">
              <button @click="edit(market)" class="btn btn-sm btn-outline-secondary"><i class="fas fa-cog"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <SetOffPayRateModal :is-show="isShowModal" :rates="rates" :data="setMarket" @close="modalClosed" />
  </div>
</template>
<script>
import _ from 'lodash'
import Swal from 'sweetalert2'

import MarketService from '@/services/marketService'
import SetOffPayRateModal from './components/SetOffPayRateModal'

export default {
  name: 'OffPayRate',
  components: {
    SetOffPayRateModal
  },
  data() {
    return {
      data: [],
      setMarket: null,
      isShowModal: false
    }
  },
  computed: {
    markets() {
      return this.data?.markets ?? []
    },
    groups() {
      if(this.markets.length) {

        const groups = _.orderBy(this.$store.state.marketGroups, ['sort'], ['asc'])
        .map((group)=>{
          const markets = this.markets.filter((market)=>{
            return market.groupId === group._id
          })
          group['markets'] = _.orderBy(markets, ['sort'], ['asc'])
          return group
        })

        return groups.filter(g=>g.markets.length)
      }else{

        return null
      }
    },
    rates() {
      return _.orderBy((this.data?.rates ?? []), ['sort'], ['asc'])
    }
  },
  methods: {
    loadData() {
      MarketService.getOffPayRate().then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    edit(market) {
      this.setMarket = market
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadData()
      }
    }
  },
  mounted() {
    this.loadData()

    if(!this.$store.state.marketGroups.length) {
      this.$store.dispatch('reloadMarketGroups')
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        font-size: 85%;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
